<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="showing"
      width="550px"
      min-height="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="hide"
    >
      <div v-loading="loading">
        <!-- 一个表单 -->
        <el-form
          ref="formInline"
          :model="formInline"
          @submit.native.prevent
          :rules="rules"
        >
          <div class="banbentwo">
            <div class="banbentwoItem">
              <div>禁用说明</div>
              <textarea
                ref="statusRemark"
                v-model="formInline.statusRemark"
                placeholder="请输入禁用说明"
                maxlength="120"
              >
              </textarea>
            </div>
          </div>
          <div class="sure">
            <el-button
              size="small"
              type="primary"
              @click="determine('formInline')"
              >确定</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getRequest, postRequest } from "@/api/api.js";
import { regMatch } from "../../utils/reg";
export default {
  data() {
    return {
      //审核弹窗
      title: "",
      showing: false,
      loading: false,
      bedisabled: false,
      index: null,
      //表单
      formInline: {
        statusRemark: "",
      },
      //表单验证
      rules: {
        statusRemark: [
          {
            required: true,
            message: "请输入禁用说明，不能为空",
            trigger: "blur",
          },
        ],
      },
    };
  },
  props: ["state"],
  methods: {
    hide() {
      this.fileListUpload = [];
      this.showing = false;
      this.$refs.formInline.resetFields(); //清空表单
    },
    show(item, val, modecode) {
      this.title = item;
      this.showing = true;
      this.formInline = {};
      this.modecode = modecode;
      this.formInline = JSON.parse(JSON.stringify(val));
    },
    determine(formData) {
      // 表单验证-----------
      if (!regMatch(this.formInline.statusRemark, "禁用说明")) {
        this.$refs.statusRemark.focus();
        return;
      }
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        status: 1,
        code: this.formInline.code,
        statusRemark: this.formInline.statusRemark, //禁用说明 必须
      };
      console.log(parms);
      postRequest("updateByHotelPowerStatus", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "禁用成功!",
            type: "success",
          });
          this.hide();
          this.$parent.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
.el-form-item__label {
  display: block !important;
  width: 100% !important;
}
.el-radio-group {
  width: 100%;
}
.el-button {
  width: 200px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 32px;
}
.el-button:hover {
  transform: scale(1.1);
}
.sure {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #d8d8d8;
}
.banben {
  display: flex;
  justify-content: space-between;
}
.banbentwo {
  width: 100%;
}
.banbentwoItem {
  width: 100%;
  margin-bottom: 16px;
}
.banbentwoItem > textarea {
  width: 100%;
  height: 170px !important;
  background: #fefeff;
  box-sizing: border-box;
  border: 1px solid #e8ecef;
  border-radius: 4px;
  padding-left: 15px;
  outline: none;
  padding-top: 10px;
}
textarea::-webkit-input-placeholder {
  color: #000000;
  opacity: 0.25;
}
.banbentwoItem > div:first-child {
  margin-bottom: 12px;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  color: #000000;
  opacity: 0.65;
}
</style>