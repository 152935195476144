<template>
  <div class="outer">
    <div class="hl-header">
      <div class="hl-search">
        <div class="hl-title">酒店管理</div>
        <el-button
          v-if="chuangjian"
          type="primary"
          @click="addIdType(0, null)"
          class="addIdType"
          >+ 创建酒店</el-button
        >
      </div>
    </div>
    <div class="hl-content">
      <!-- <div class="mokuai">酒店管理</div> -->
      <el-table
        class="hl-table"
        :data="tableData"
        height="90%"
        :header-cell-style="{ background: '#F4F5F9', color: '#000000' }"
        :default-sort="{ prop: 'sort' }"
      >
        <el-table-column
          label="序号"
          type="index"
          :index="table_index"
          width="100px"
        ></el-table-column>
        <el-table-column label="酒店名称">
          <template slot-scope="scope">
            <span>{{ scope.row.hotelName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="欢迎词" :show-overflow-tooltip="true">
          <template slot-scope="scope">
            <span>{{ scope.row.welcome }}</span>
          </template>
        </el-table-column>
        <el-table-column label="音乐地址">
          <template slot-scope="scope">
            <span>{{ scope.row.musicUrl }}</span>
          </template>
        </el-table-column>
        <el-table-column label="排序">
          <template slot-scope="scope">
            <span>{{ scope.row.dispOrder }}</span>
          </template>
        </el-table-column>
        <el-table-column label="备注">
          <template slot-scope="scope">
            <span>{{ scope.row.remark }}</span>
          </template>
        </el-table-column>
        <el-table-column label="试用期到期">
          <template slot-scope="scope">
            <span>{{ scope.row.validDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="欢迎语推送延时">
          <template slot-scope="scope">
            <span>{{ scope.row.delay }}</span>
          </template>
        </el-table-column>
        <el-table-column label="酒店公钥">
          <template slot-scope="scope">
            <p class="gongYao" @click="copyWx(scope.row.apiKey)">
              {{ scope.row.apiKey }}
            </p>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="150px">
          <template slot-scope="scope">
            <div class="cz">
              <div class="qidong">
                <el-button v-if="xiugai" type="text" @click="edit(scope.row)"
                  >编辑</el-button
                >
                <el-button
                  style="color: red"
                  type="text"
                  @click="del(scope.row)"
                  >删除</el-button
                >
              </div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @size-change="onPaginationSize"
          @current-change="onPaginationIndex"
          :current-page="condition.offset"
          :page-size="condition.num"
          layout=" prev, pager, next, jumper,total"
          :total="totolNums"
        ></el-pagination>
      </div>
    </div>
    <!-- 删除 -->
    <el-dialog title="提示" :visible.sync="deleteVisible" width="30%">
      <el-input
        placeholder="请输入删除指示"
        v-model="todelete"
        style="width: 100%"
        maxlength="10"
      ></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteSure">确 定</el-button>
      </span>
    </el-dialog>
    <addModify ref="addModify" />
    <disableModify ref="disableModify" />
  </div>
</template>
<script>
import { getRequest, postRequest, deleteRequest } from "@/api/api.js";
import addModify from "@/views/hotel/addModify.vue";
import disableModify from "@/views/hotel/disableModify.vue";

export default {
  data() {
    return {
      tableData: [],
      input: "",
      totolNums: 0,
      modeCode: null,
      status: 0,
      condition: {
        offset: 1,
        num: 10,
      },
      infor: {},
      chuangjian: false,
      xiugai: false,
      shanchu: false,
      deleteVisible: false,
      row: {},
      todelete: "",
    };
  },
  components: {
    addModify,
    disableModify,
  },
  created() {
    if (!this.power.checkedpower("0102004")) {
      // 在PMS管理系统里面-获取权限
      this.$router.push("power");
    }
    // // 创建
    this.chuangjian = this.power.checkedpower("0102001");
    // // 修改
    this.xiugai = this.power.checkedpower("0102002");
    // // 删除，禁用
    this.shanchu = this.power.checkedpower("0102003");

    this.infor = this.$route.query;
    this.condition.modeCode = this.infor.code;
    this.getFounderList();
  },
  methods: {
    // 获取权限列表数据
    getFounderList() {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectAllHotel", this.condition).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.tableData = res.data.list;
          this.totolNums = res.data.total;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 序号
    table_index(index) {
      return (this.condition.offset - 1) * this.condition.offset + index + 1;
    },
    // 删除
    del(row) {
      this.deleteVisible = true;
      this.todelete = "";
      this.row = row;
    },
    deleteSure() {
      if (this.todelete != "yes") {
        this.$message({
          duration: 5000,
          message: "请输入指示！",
          center: true,
        });
        return;
      }
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        id: this.row.id,
      };
      deleteRequest("deleteSysHotel", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "删除成功!",
            type: "success",
          });
          this.deleteVisible = false;
          this.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    // 启动
    qidong(row) {
      let loadingInstance = this.$loading({ text: "加载中" }); //如果这数据不存在，则要去接口请求数据
      let parms = {
        status: 0,
        modecode: this.infor.code,
        code: row.code,
        statusRemark: "", //禁用说明 必须
      };
      postRequest("/updateByHotelPowerStatus", parms).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        }); //不回调
        if (res.status == 200) {
          this.$message({
            duration: 5000,
            message: "启动成功!",
            type: "success",
          });
          this.getFounderList();
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            type: "error",
            hasClose: true,
          });
        }
      });
    },
    disable(index, row) {
      let state = "禁用";
      let modecode = this.infor.code;
      this.$refs.disableModify.show(state, row, modecode);
    },
    copyWx(item) {
      var input = document.createElement("input");
      input.value = item; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message({
        duration: 5000,
        message: "复制成功！",
        center: true,
        type: "success",
      });
      return;
      let url = item;
      //新建一个文本框
      let oInput = document.createElement("input");
      //赋值给文本框
      oInput.value = url;
      document.body.appendChild(oInput);
      // 选择对象;
      oInput.select();
      // 执行浏览器复制命令
      document.execCommand("Copy");
      //复制完成删除掉输入框
      oInput.remove();
      console.log("复制成功");
    },

    // 修改
    edit(row) {
      let state = "编辑";
      this.$refs.addModify.show(state, this.infor, row);
    },
    // 新增
    addIdType() {
      let state = "创建";
      this.$refs.addModify.show(state);
    },
    hotel() {
      this.$router.push("/hotel");
    },
    changePage(val) {
      this.condition.offset = val;
      this.getFounderList();
    },
    // 每页的数据数
    onPaginationSize(val) {
      this.condition.num = val;
      this.getFounderList();
    },
    // 页数
    onPaginationIndex(val) {
      this.condition.offset = val;
      this.getFounderList();
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.outer {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.button {
  width: 60px;
  height: 30px;
  /* background:rgb(163, 197, 248) ; */
  background-color: cornflowerblue;
  color: white;
  border: none;
}
.button2 {
  background-color: white;
  border: 1px solid #999;
  color: #666;
}
.hl-header {
  width: 100%;
  height: 60px;
  display: flex;
}
.hl-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  position: relative;
}
.hl-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
}
.addIdType {
  width: 136px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.hl-search .span {
  width: 40px;
  height: 30px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 14px;
  border: none;
}
.hl-content {
  flex: 1;
  background-color: #fff;
  padding: 21px 32px;
  position: relative;
}
.mokuai {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.85;
  margin-bottom: 21px;
}
.hl-table {
  /* background: red; */
  overflow: auto;
}
.cz-button {
  width: 74px;
  height: 28px;
  background: #6ec543;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
}
.qidong {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
}
.line {
  display: inline-block;
  height: 14px;
  border: 1px solid #e8ecef;
}
.cz {
  display: flex;
}
.qiyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #387dff;
  border-radius: 50%;
  margin-right: 6px;
}
.jinyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 50%;
  margin-right: 6px;
}
.zt > div {
  display: flex;
  align-items: center;
}
.dialog-footer > .el-button {
  width: 60px;
  height: 30px;
}
.el-table {
  height: 90%;
  overflow: hidden;
}
.block {
  position: absolute;
  right: 32px;
  height: 10%;
}
.gongYao {
  cursor: pointer;
}
</style>